import React from 'react'
import { Link } from 'gatsby'
import Helmet from 'react-helmet'
import Banner from '../components/Banner'
import Layout from '../components/layout'

class HomeIndex extends React.Component {
    render() {
        return (
            <Layout>
                <Helmet
                    title="Sam & Jenny">
                </Helmet>

                <Banner />
                <div id="main">
                    <section id="one" className="tiles">
                        <article>
                            <header className="major">
                                <h3>Seven Ponds Farm</h3>
                                <p>The venue</p>
                            </header>
                            <Link to="/venue" className="link primary"></Link>
                        </article>
                        <article>
                            <header className="major">
                                <h3>Schedule of Events</h3>
                                <p>The Weekend Rundown</p>
                            </header>
                            <Link to="/schedule" className="link primary"></Link>
                        </article>
                        <article>
                            <header className="major">
                                <h3>Activities & Where to Stay</h3>
                                <p>Enjoy your weekend in the Catskills</p>
                            </header>
                            <Link to="/lodging" className="link primary"></Link>
                        </article>
                        <article>
                            <header className="major">
                                <h3>The Couple</h3>
                                <p>This is us</p>
                            </header>
                            <Link to="/thecouple" className="link primary"></Link>
                        </article>
                        <article>
                            <header className="major">
                                <h3>Travel</h3>
                                <p>Try not to get lost</p>
                            </header>
                            <Link to="/travel" className="link primary"></Link>
                        </article>
                        <article>
                            <header className="major">
                                <h3>Registry</h3>
                                <p>Invest in our future</p>
                            </header>
                            <Link to="/registry" className="link primary"></Link>
                        </article>
                    </section>

                </div>
            </Layout>
        )
    }
}

export default HomeIndex
import React from 'react'
import Badge from 'react-bootstrap/Badge'

import { Link } from 'gatsby'

const Banner = (props) => (
    <section id="banner" className="major">
    
        <div id="one" className="bgcontainer"></div>
        <div id="two" className="bgcontainer"></div>
        <div id="three" className="bgcontainer"></div>
        <div id="four" className="bgcontainer"></div>
        <div id="five" className="bgcontainer"></div>
        <div id="six" className="bgcontainer"></div>
        <div className="inner">
            <header className="major">
                <h1>Sam & Jenny</h1>
            </header>
            <div>
                <h3>June 15, 2019 </h3>
                <div id="rsvp">
                <Link className="button special" to="/lodging">EXCITING UPDATE: Activities around Summit</Link>
               
                </div>
            </div>

        </div>
        
    </section>
)

export default Banner
